import React from "react";
import Slider from "react-slick";
import SingleTeam from "../../components/Team/SingleTeam";
import SectionTitle from "../../components/Common/SectionTitle";

// Team Members
import teamimg1 from "../../assets/img/team1.jpg";
import teamimg2 from "../../assets/img/team2.jpg";
import teamimg3 from "../../assets/img/team3.jpg";
import teamimg7 from "../../assets/img/team/7.jpg";

const Team = () => {
  function NextArrow(props) {
    const { className, onClick } = props;
    return (
      <button type="button" onClick={onClick} className={className}>
        <i className="flaticon-right-arrow"></i>
      </button>
    );
  }

  function PrevArrow(props) {
    const { className, onClick } = props;
    return (
      <button type="button" onClick={onClick} className={className}>
        {" "}
        <i className="flaticon-left-arrow"></i>
      </button>
    );
  }

  const slilderSettings = {
    dots: false,
    centerMode: false,
    infinite: true,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    className: "active",
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <React.Fragment>
      <div
        id="rs-team"
        className="rs-team style1 nav-style2 gray-bg pt-94 pb-100 md-pt-64 md-pb-70"
      >
        <div className="container">
          <div className="row y-middle mb-50 md-mb-30">
            <div className="col-md-6 sm-mb-30">
              <SectionTitle
                sectionClass="sec-title"
                subtitleClass="sub-title primary"
                subtitle="Meet our Team"
                titleClass="title mb-0"
                title="Industry Experts"
              />
            </div>
          </div>
          <Slider {...slilderSettings}>
            <SingleTeam
              itemClass="team-item"
              Image={teamimg1}
              Title="Aliyu Rabiu Abdul"
              Designation="CEO & Founder"
              facebook="https://facebook.com/rabalgaro"
              twitter="https://x.com/rabalgaro"
              linkedin="https://www.linkedin.com/in/aliyura/"
              instagram="https://www.instagram.com/rabalgaro/"
            />

            <SingleTeam
              teamClass="team-item"
              Image={teamimg2}
              Title="Umar Nuhu Abba"
              Designation="COO/Director"
              facebook="https://www.facebook.com/i.am.dr.algo?mibextid=ZbWKwL"
              twitter="https://x.com/i_am_dr_algo?s=21"
              linkedin="https://www.linkedin.com/in/umar-nuhu-abba-14a9671b6/"
              instagram="https://www.instagram.com/i_am_dr_algo?igsh=OTBra2ozeWFwc3Bp&utm_source=qr"
            />

            <SingleTeam
              teamClass="team-item"
              Image={teamimg3}
              Title="Bashir Ridwan"
              Designation="Product Manager"
              facebook="#"
              twitter="#"
              linkedin="#"
              instagram="#"
            />

            {/* <SingleTeam
              teamClass="team-item"
              Image={teamimg7}
              Title="Fatima Joel Ajemasu"
              Designation="Human Resource"
              facebook="#"
              twitter="#"
              linkedin="#"
              instagram="#"
            /> */}
          </Slider>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Team;
