import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from "react-accessible-accordion";

const Faq = () => {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(!isOpen);

  return (
    <div className="rs-faq-part style1 pt-100 pb-100 md-pt-70 md-pb-70">
      <ModalVideo
        channel="youtube"
        isOpen={isOpen}
        videoId="YLN1Argi7ik"
        onClose={() => {
          openModal();
        }}
      />
      <div className="container">
        <div className="row">
          <div className="col-lg-6 padding-0">
            <div className="main-part">
              <div className="title mb-40 md-mb-14">
                <h2 className="text-part">Frequently Asked Questions</h2>
              </div>
              <div className="faq-content">
                <Accordion className="accordion-style1" preExpanded={"a"}>
                  <AccordionItem className="accordion-item" uuid="a">
                    <AccordionItemHeading className="card-header">
                      <AccordionItemButton className="card-link">
                        What are the requirements to study in Syrol?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="card-body">
                      Admission requirements at Syrol vary depending on the
                      course you choose. Generally, you'll need a reliable
                      internet connection and a laptop or equivalent device.
                      Some programs may have specific prerequisites, such as
                      basic programming skills or prior relevant coursework. We
                      recommend checking the details of your desired course for
                      complete admission criteria.
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem className="accordion-item" uuid="b">
                    <AccordionItemHeading className="card-header">
                      <AccordionItemButton className="card-link">
                        Does Syrol offer free courses?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="card-body">
                      Yes, Syrol provides free courses for children aged 8 to 14
                      and individuals with disabilities. To qualify for these
                      complimentary programs, applicants must undergo
                      personality and background assessments
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem className="accordion-item" uuid="c">
                    <AccordionItemHeading className="card-header">
                      <AccordionItemButton className="card-link">
                        Does Syrol offer scholarships?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="card-body">
                      Yes, Syrol offers scholarships to eligible students based
                      on both merit and financial need. We believe in making
                      quality tech education accessible to deserving candidates.
                      To stay informed about these opportunities, we encourage
                      you to follow our social media channels closely. Whenever
                      a new scholarship becomes available, we promptly announce
                      it on our platforms, allowing interested individuals to
                      apply.
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem className="accordion-item" uuid="d">
                    <AccordionItemHeading className="card-header">
                      <AccordionItemButton className="card-link">
                        How to get Syrol to build an app for me
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="card-body">
                      Put together requirements in a document, contact us via
                      our email hello@syroltech.com or phone number or send us
                      message{" "}
                      <a
                        href="https://syroltech.com/contact-us"
                        className="text-danger"
                      >
                        here
                      </a>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem className="accordion-item" uuid="e">
                    <AccordionItemHeading className="card-header">
                      <AccordionItemButton className="card-link">
                        Can i study in Syrol online?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="card-body">
                      Yes, Syrol has an intensive learning management platform
                      that is available online, you can learn on the go, and
                      stay connected with your peers and instructors.
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem className="accordion-item" uuid="e">
                    <AccordionItemHeading className="card-header">
                      <AccordionItemButton className="card-link">
                        How does Syrol AI trading bot works?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="card-body">
                      Our AI trading bot analyzes the market with our
                      well-standard market strategy, we develop and train the AI
                      to maximize your income while offline. The AI uses any
                      broker of your choice, all you need is to provide your MT4
                      server details and we tell our AI to trade for you.
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
          </div>
          <div className="col-lg-6 padding-0">
            <div className="img-part media-icon">
              <a
                className="popup-videos"
                onClick={() => {
                  openModal();
                }}
              >
                <i className="fa fa-play"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
