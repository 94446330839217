import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import SectionTitle from "../../components/Common/SectionTitle";
import Marquee from "react-fast-marquee";
import gridImg1 from "../../assets/img/img1.jpg";
import gridImg2 from "../../assets/img/img2.jpg";
import { AnnouncementService } from "../../services/AnnouncementService";

function About() {
  const [state, setState] = useState(true);

  const counters = [
    {
      countNum: 11,
      countTitle: "Students",
      counterPrefix: "k+",
    },
    {
      countNum: 43,
      countTitle: "Studying",
      counterPrefix: "%",
    },
    {
      countNum: 33,
      countTitle: "Employed",
      counterPrefix: "%",
    },
  ];

  const [items, setItems] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const getAnnoucements = () => {
    AnnouncementService.get(0)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          const payload = response.data.data;
          setItems(payload.page);
        }
      })
      .catch((ex) => {
        console.log(ex);
        setLoading(false);
      });
  };

  useEffect(() => {
    getAnnoucements();
  }, []);

  return (
    <div
      id="rs-about"
      className="rs-about style2 pt-94 pb-100 md-pt-64 md-pb-70"
    >
      {items && items.length > 0 ? (
        <div className="annoucements d-none d-sm-block">
          <Marquee pauseOnHover={true} delay={2}>
            {items.map((item, idx) => {
              return <h3 key={idx}>{item.title}</h3>;
            })}
          </Marquee>
        </div>
      ) : (
        <></>
      )}
      <div className="container">
        <div className="row">
          <div className="col-lg-5 pr-64 md-pr-14 md-mb-50">
            <div className="about-intro">
              {/* Section Title Start */}
              <SectionTitle
                sectionClass="sec-title mb-40"
                subtitleClass="sub-title primary"
                titleClass="title mb-22"
                title="About Syrol"
                descClass="desc big"
                description="Syrol Technologies is a leading Nigerian tech company that offers IT services ranging from website development, mobile application development, artificial intelligence, auto forex trading bots, data science models, comprehensive tracking devices, and vocational training to a wide range of entities all over Africa."
              />
              {/* Section Title End */}
              <div className="btn-part">
                <Link to="/about-us" className="readon2">
                  Read More
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-7 ml--40 md-ml-0">
            {counters && (
              <div className="row rs-counter couter-area mb-40">
                {counters
                  .map((counter, num) => (
                    <div key={num} className="col-md-4">
                      <div className="counter-item one">
                        <h2 className="number rs-count">
                          <CountUp
                            start={state ? 0 : counter.countNum}
                            end={counter.countNum}
                            duration={10}
                            onEnd={() => setState(false)}
                          />
                          {({ countUpRef, start }) => (
                            <VisibilitySensor onChange={start} delayedCall>
                              <span ref={countUpRef} />
                            </VisibilitySensor>
                          )}
                          <span className="counter-prefix">
                            {counter.counterPrefix}
                          </span>
                        </h2>
                        <h4 className="title mb-0">{counter.countTitle}</h4>
                      </div>
                    </div>
                  ))
                  .slice(0, 1)}
                {counters
                  .map((counter, num) => (
                    <div key={num} className="col-md-4">
                      <div className="counter-item two">
                        <h2 className="number rs-count">
                          3.
                          <CountUp
                            start={state ? 0 : counter.countNum}
                            end={counter.countNum}
                            duration={10}
                            onEnd={() => setState(false)}
                          />
                          {({ countUpRef, start }) => (
                            <VisibilitySensor onChange={start} delayedCall>
                              <span ref={countUpRef} />
                            </VisibilitySensor>
                          )}
                          <span className="counter-prefix">
                            {counter.counterPrefix}
                          </span>
                        </h2>
                        <h4 className="title mb-0">{counter.countTitle}</h4>
                      </div>
                    </div>
                  ))
                  .slice(1, 2)}
                {counters
                  .map((counter, num) => (
                    <div key={num} className="col-md-4">
                      <div className="counter-item three">
                        <h2 className="number rs-count">
                          <CountUp
                            start={state ? 0 : counter.countNum}
                            end={counter.countNum}
                            duration={10}
                            onEnd={() => setState(false)}
                          />
                          {({ countUpRef, start }) => (
                            <VisibilitySensor onChange={start} delayedCall>
                              <span ref={countUpRef} />
                            </VisibilitySensor>
                          )}
                          <span className="counter-prefix">
                            {counter.counterPrefix}
                          </span>
                        </h2>
                        <h4 className="title mb-0">{counter.countTitle}</h4>
                      </div>
                    </div>
                  ))
                  .slice(2, 3)}
              </div>
            )}

            <div className="row grid-area">
              <div className="col-md-6 sm-mb-30">
                <div className="image-grid">
                  <img src={gridImg1} alt="grid img" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="image-grid">
                  <img src={gridImg2} alt="grid img" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {items && items.length > 0 ? (
        <div className="annoucements pt-20 mt-20  d-sm-none  d-block">
          <Marquee pauseOnHover={true} delay={2}>
            {items.map((item, idx) => {
              return <h3 key={idx}>{item.title}</h3>;
            })}
          </Marquee>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default About;
