import React from 'react';

import aboutImg from '../../assets/img/choose/home12/1.png';
import icon1 from '../../assets/img/choose/home12/icon/1.png';
import icon2 from '../../assets/img/choose/home12/icon/2.png';
import icon3 from '../../assets/img/choose/home12/icon/3.png';

import bgImg from '../../assets/img/bg/home12/why-learn-bg.jpg';

const bgStyle = {
    backgroundImage:`url(${bgImg})`
}

const WhyChooseUs = () => {

    return (
        <div className="why-choose-us style3" style={bgStyle}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 md-mb-40">
                        <div className="img-part">
                            <img src={aboutImg} alt="" />                        
                        </div>
                    </div>
                    <div className="col-lg-6 pl-60 md-pl-14">
                        <div className="sec-title3 mb-30">
                            <h2 className=" title new-title margin-0 pb-14">Why Syrol</h2>
                            <div className="new-desc">At Syrol Technologies, we equip individuals with the skills to thrive in a technology-driven world, enhancing both their personal effectiveness and professional trajectories.</div>
                        </div>
                        <div className="services-part mb-20">
                            <div className="services-icon">
                                <img src={icon1} alt="" />
                            </div>
                            <div className="services-text">
                                <h2 className="title"> Lower Learning Cost</h2>
                                <p className="services-txt">We pride ourselves on affordability. Our tuition fees are structured to alleviate financial concerns, allowing students to focus fully on their learning journey and maximize their potential. </p>
                            </div>
                        </div> 
                        <div className="services-part mb-20">
                            <div className="services-icon">
                                <img src={icon2} alt="" />
                            </div>
                            <div className="services-text">
                                <h2 className="title"> Learn From  Industry Experts</h2>
                                <p className="services-txt"> Genius is not innate; it's cultivated. Our team of Syrol tech experts is committed to nurturing your talents, transforming you into a technological virtuoso through expert guidance and hands-on experience.</p>
                            </div>
                        </div> 
                        <div className="services-part">
                            <div className="services-icon">
                                <img src={icon3} alt="" />
                            </div>
                            <div className="services-text">
                                <h2 className="title">Job Placement</h2>
                                <p className="services-txt">Your search for career opportunities ends here. We not only provide comprehensive training but also connect you with high-paying jobs in the tech industry, bridging the gap between education and employment.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WhyChooseUs;