import React from "react";
import { Link } from "react-router-dom";
import SectionTitle from "../../components/Common/SectionTitle";
import SingleServiceSeven from "../../components/Service/SingleServiceSeven";

import serviceImg1 from "../../assets/img/forex development.webp";
import serviceImg2 from "../../assets/img/gps.jpg";
import serviceImg3 from "../../assets/img/robotics.png";

import serviceImg4 from "../../assets/img/software development.png";
import serviceImg5 from "../../assets/img/vocational training.png";
import serviceImg6 from "../../assets/img/job placement.png";

import bgImg from "../../assets/img/bg/home12/services-bg.jpg";

const bgStyle = {
  backgroundImage: `url(${bgImg})`,
};

const Service = () => {
  return (
    <React.Fragment>
      <div
        id="rs-services"
        className="rs-services home12-style"
        style={bgStyle}
      >
        <div className="container">
          <SectionTitle
            sectionClass="sec-title4 text-center mb-50"
            subtitleClass="sub-title"
            subtitle="Know More About"
            titleClass="title"
            title="What we do"
          />

          <div className="row">
            <div className="col-lg-4 md-mb-30">
              <SingleServiceSeven
                serviceClass="services-item"
                serviceImg={serviceImg4}
                serviceTitle="Software Development"
                serviceDesc="We design and develop software solutions engineered to deliver optimal results. Our approach focuses on maximizing outcomes while maintaining cost-effectiveness in line with current market standards"
              />
            </div>
            <div className="col-lg-4 md-mb-30">
              <SingleServiceSeven
                serviceClass="services-item"
                serviceImg={serviceImg5}
                serviceTitle="Vocational Training"
                serviceDesc="We provide comprehensive, hands-on IT-related training and mentorship programs. Our services cater to governmental organizations, NGOs, and individuals, offering instruction from zero to advanced"
              />
            </div>
            <div className="col-lg-4">
              <SingleServiceSeven
                serviceClass="services-item"
                serviceImg={serviceImg6}
                serviceTitle="Job Placement"
                serviceDesc="Our program transforms both IT and non-IT students into industry-ready professionals through rigorous training, from basic to advanced concepts. We then facilitate their entry into the tech industry."
              />
            </div>
          </div>
          <div className="row  mt-20">
            <div className="col-lg-4 md-mb-30">
              <SingleServiceSeven
                serviceClass="services-item"
                serviceImg={serviceImg1}
                serviceTitle="Forex Trading & Training"
                serviceDesc="Learn how to take your first steps in trading and start developing your strategy. We offer both free and paid technical and fundamental forex trading and training and endless mentorship to secure your career in forex."
              />
            </div>

            <div className="col-lg-4 md-mb-30">
              <SingleServiceSeven
                serviceClass="services-item"
                serviceImg={serviceImg3}
                serviceTitle="Robotics & Embedding Systems"
                serviceDesc="We develop and mentor others to design, build, and program cutting-edge robots and embedded systems
                Develop hands-on experience with industry-leading tools and AI, machine learning, and IoT applications."
              />
            </div>

            <div className="col-lg-4">
              <SingleServiceSeven
                serviceClass="services-item"
                serviceImg={serviceImg2}
                serviceTitle="GPS Tracking Devices"
                serviceDesc="We provide tracking systems and devices that use Global Positioning System (GPS) technology and IP satellites for governmental organizations, NGOs, and individuals to track the location of people, objects, or assets. "
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Service;
