import React from "react";
import SingleTestimonialTwo from "../../components/Testimonial/SingleTestimonialTwo";
import avatar1 from "../../assets/img/testimonial/style2/1.jpg";
import avatar2 from "../../assets/img/testimonial/style2/2.jpg";
import SectionTitle from "../../components/Common/SectionTitle";

const QuoteSection = () => {
  return (
    <div className="rs-testimonial style2 pb-20 d-pt-70 md-pb-70">
      <div className="container">
        <SectionTitle
          sectionClass="sec-title mb-50 md-mb-30 text-center"
          subtitleClass="sub-title primary"
          subtitle="Get Motivated With These"
          titleClass="title mb-0"
          title="Intresting Quotes"
        />
        <div className="row">
          <div className="col-lg-6 lg-p4-0 ml--14  md-ml-0">
            <SingleTestimonialTwo
              testiImage={avatar1}
              name="Mahadi mansura"
              designation="Head Teacher"
              description="Education is the passport to the future for tomorrow belongs to those who prepare for it today"
            />
          </div>
          <div className="col-lg-6 lg-pl-3 ml--14 md-ml-0">
            <SingleTestimonialTwo
              animateDelay="50"
              itemClass="testi-wrap"
              testiImage={avatar2}
              name="Jonathon Lary"
              designation="Inventor"
              description="Technology touches everyone, everywhere, and everyone should be part of it today for better tommorow"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuoteSection;
