import React from "react";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import SectionTitle from "../../components/Common/SectionTitle";
import ContactForm from "../../components/Contact/ContactForm";
import ContactInfo from "../../components/Contact/ContactInfo";

import bannerbg from "../../assets/img/breadcrumbs/inner13.jpg";

const ContactMain = () => {
  return (
    <React.Fragment>
      {/* SiteBreadcrumb */}
      <SiteBreadcrumb
        pageTitle="Contact"
        pageName="Contact"
        breadcrumbsImg={bannerbg}
      />
      {/* SiteBreadcrumb */}

      {/* Contact Section Start */}
      <div className="rs-contact style1 event-bg pt-110 md-pt-10 pb-100 md-pb-80">
        <div className="container pb-66 md-pb-46">
          <div className="row gutter-35">
            <div className="col-md-4">
              <ContactInfo
                boxClass="sm-mb-30"
                title="Address"
                iconClass="flaticon-location"
                address="Ihesiaba Court, Ishaya Shekari Crescent by Federal Staff
                Housing Estate/Setraco Gate, Gwarimpa, Abuja, FCT Nigeria"
              />
            </div>
            <div className="col-md-4">
              <ContactInfo
                boxClass="sm-mb-30"
                title="Email Address"
                iconClass="flaticon-email"
                email="hello@syroltech.com, recruitment@syroltech.com"
              />
            </div>
            <div className="col-md-4">
              <ContactInfo
                boxClass=""
                title="Phone Number"
                iconClass="flaticon-phone"
                phone="(+234)9043205555, (+234)8064160204"
              />
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row y-middle">
            <div className="col-lg-6 md-mb-30">
              <div className="map-canvas">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3940.5211729799976!2d7.439531775018734!3d9.016129691044688!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x104e0d946ece1c7f%3A0xfc5433a7622ca5ba!2sGames%20Village%20Estate%20Abuja%2C%20FCT!5e0!3m2!1sen!2sng!4v1719991316380!5m2!1sen!2sng"></iframe>
              </div>
            </div>
            <div className="col-lg-6 pl-60 md-pl-14">
              <div className="contact-widget">
                <SectionTitle
                  sectionClass="sec-title3 mb-36"
                  titleClass="title black-color mb-14"
                  title="Get in Touch"
                  descClass="new-desc"
                  description="Have some suggestions or just want to say hi? Our  support team are ready to help you 24/7."
                />
                {/* Contact Form */}
                <ContactForm submitBtnClass="btn-send" btnText="Submit" />
                {/* Contact Form */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Contact Section End */}
    </React.Fragment>
  );
};

export default ContactMain;
