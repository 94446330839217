import React from 'react';
import Slider from "react-slick";
import SectionTitle from '../../components/Common/SectionTitle';
import SingleTestimonial from '../../components/Testimonial/SingleTestimonial';


import quote from '../../assets/img/testimonial/style5/quote2.png';
import author1 from '../../assets/img/testmonial1.jpeg';
import author2 from '../../assets/img/testmonial2.png';
import author3 from '../../assets/img/testmonial3.jpg';

const Testimonial = () => {

    const testimonialSettings = {
        dots: true,
        centerMode: false,
        infinite: true,
        arrows: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                    dots: false,
                }
            }
        ]
    };


    return (
        <React.Fragment>
            <div className="rs-testimonial home-style1 pt-100 pb-150 md-pt-70 md-pb-70">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title mb-50 md-mb-30 text-center"
                        subtitleClass="sub-title primary"
                        subtitle="Testimonial"
                        titleClass="title mb-0"
                        title="What Clients Saying"
                    />
                    <Slider {...testimonialSettings}>
                        <SingleTestimonial
                            itemClass="testi-item"
                            quoteImage={quote}
                            authorImage={author1}
                            Title="Khalid Muhammad"
                            Designation="CEO Dopal Technologies"
                            Description="We are glad with those backend engineers that we hired from you last year, they are well-trained and knowledgable. The leading developers we have in our team are those we got from you.  We would like you to get us two more NestJ engineers, we will hire them by month-end."
                        />
                        <SingleTestimonial
                            itemClass="testi-item"
                            quoteImage={quote}
                            authorImage={author2}
                            Title="Muhammad A Muhammad"
                            Designation="CEO Guarantee Impex"
                            Description="Thank you very much Syrol for the app you built for our rice processing company. We have been using the software for like 3 years now, and it has been stable all this while. You are my point of contact when it comes to application development for Mobile, Web, or Desktop."
                        />
                        <SingleTestimonial
                            itemClass="testi-item"
                            quoteImage={quote}
                            authorImage={author3}
                            Title="Theresa Oyenuga"
                            Designation="Software Engineer"
                            Description="Syrol Technologies is an innate genius and a true enthusiast who has that unflinching ardor to share their technologies with youth. Syrol expertise is really worth applauding. Keep soaring. I have done their Junior software developers program. and not I am working with eTransact"
                        />
                    </Slider>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Testimonial